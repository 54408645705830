@import '@/assets/scss/_variables.scss';

.WalletMethod {

  &_container {
    @media only screen and (min-width: $tablet-width) {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  &_form {
    flex-grow: 1;
    margin: 0 0 20px;
    @media only screen and (min-width: $tablet-width) {
      margin: 0 30px 0 0;
    }
  }

  &_verification {
    flex-grow: 1;
    margin: 0 0 20px;
    @media only screen and (min-width: $tablet-width) {
      margin: 0 30px 0 0;
    }
  }

  &_faq {
    flex-shrink: 0;
    @media only screen and (min-width: $tablet-width) {
      flex-basis: 390px;
    }
  }

  &_row {
    margin: 0 0 15px;

    &:last-child {
      margin: 0;
    }

    &--twoColumns {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 10px;
    } 

    &--btns {
      display: grid;
      grid-template-columns: 1fr;
      column-gap: 10px;
      row-gap: 10px;
      @media only screen and (min-width: $tablet-width) {
        grid-template-columns: 1fr 1fr;
      }
    }

  }

  &_title {
    margin: 0 0 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    text-align: left;
    @media only screen and (min-width: $tablet-width) {
      font-size: 21px;
      line-height: 25px;
    }
  }

  &_separator {
    height: 1px;
    margin: 12px 0;
    background: #2AF3F3;
    border-radius: 4px;
    opacity: 0.1;
  }
  
  &_Confirm_title {
    font-weight: 500;
    font-size: 21px;
    line-height: 25px;
    text-align: left;
    text-transform: uppercase;
    color: #ffffff;
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(#2af3f3, 0.1);
    margin-bottom: 12px;
  }
  
  &_Confirm_label {
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: rgba(#fff, 0.55);
    text-align: left;
    margin-bottom: 6px;
  }
  
  &_Confirm_value {
    margin: 0 0 8px;
    font-size: 14px;
    line-height: 16px;
    color: #fff;
    text-align: left;

    @media only screen and (min-width: $tablet-width) {
      margin: 0 0 10px;
    }

    &:last-of-type {
      margin: 0;
    }
  }

  &_table {
    &_row {
      display: grid;
      grid-template-columns: 30% 63% 28px;
      text-align: left;
      align-items: center;
      margin-bottom: 15px;
    }
    &_label {
      font-size: 14px;
      line-height: 16px;
      color: #a6aebf;
    }
    &_field {
      font-size: 14px;
      line-height: 16px;
      color: #fff;
    }
  }

  &_warning {
    background: rgba(#ff556d, 0.1);
    box-shadow: 0px 18px 34px rgba(34, 39, 47, 0.1);
    border-radius: 4px;
    color: #ff556d;
    font-size: 14px;
    max-width: 302px;
    padding: 14px;
    margin: 10px auto;
    text-align: left;
    line-height: 124%;
    border: 1px solid #FF556D;
    box-shadow: 0px 18px 34px rgba(34, 39, 47, 0.1);
    position: relative;
    display: flex;
    justify-content: center;
    &::before {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 6px 6px 6px;
      border-color: transparent transparent #FF556D transparent;
      position: absolute;
      top: -6px;
    }
    @media only screen and (min-width: $tablet-width) {
      margin: 0;
      margin-left: auto;
    }
  }

  &_TextBtn_root {
    font-size: 12px;
    line-height: 14px;
    color: #2af3f3;
    margin-top: 10px;
    cursor: pointer;
    display: flex;
    text-align: left;
    svg {
      margin-right: 2px;
      vertical-align: middle;
    }
  }

  &_cardsCarousel {
    margin: -18px;
    padding: 18px;
    // margin: -14px;
    // padding: 14px;
    // overflow: visible;

    .swiper-wrapper {
      // overflow: visible;
    }

    .swiper-pagination-bullet {
      --diametr: 4px;
      width: var(--diametr);
      height: var(--diametr);
      background: #2AF3F3;
      opacity: 0.55;

      &-active {
        position: relative;
        top: 1px;
        --diametr: 6px;
        opacity: 1;
      }
    }
  }

}