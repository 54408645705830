@import '@/assets/scss/_variables.scss';

.DepositCrypto_root .TextBtn_root {
  font-size: 12px;
  line-height: 14px;
  color: #2af3f3;
  margin-top: 10px;
  cursor: pointer;
  display: block;
  text-align: left;
}

.DepositCrypto_copyBtn {
  margin-top: 20px;
}

.DepositCrypto_qrBg {
  display: inline-block;
  background: rgba(#2af3f3, 0.12);
  padding: 10px;
  border-radius: 4px;
  margin: 20px 0 0;
}

.DepositCrypto_container {
  @media only screen and (min-width: $tablet-width) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.DepositCrypto_form {
  flex-grow: 1;
  margin: 0 0 20px;
  @media only screen and (min-width: $tablet-width) {
    margin: 0 30px 0 0;
  }
}

.DepositCrypto_faq {
  @media only screen and (min-width: $tablet-width) {
    flex-basis: 390px;
  }
}

.DepositCrypto_blank {
  margin: 50px auto;
}

.DepositGateway_content {
  flex-grow: 1;
  margin: 0 0 20px;
  @media only screen and (min-width: $tablet-width) {
    margin: 0 30px 0 0;
  }
}

.DepositGateway_iframe {
  background: #fff;
  border-radius: 5px;
  width: 100%;
}
