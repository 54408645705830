@import '@/assets/scss/_variables.scss';

.DepositCrypto_root {
  .DepositCrypto_copyBtn {
    margin-top: 20px;
  }

  .DepositCrypto_qrBg {
    display: inline-block;
    background: rgba(#2af3f3, 0.12);
    padding: 10px;
    border-radius: 4px;
    margin: 20px 0 0;
  }

  .DepositCrypto_container {
    @media only screen and (min-width: $tablet-width) {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  .DepositCrypto_verification {
    flex-grow: 1;
    margin: 0 0 20px;
    @media only screen and (min-width: $tablet-width) {
      margin: 0 30px 0 0;
    }
  }

  .DepositCrypto_form {
    flex-grow: 1;
    margin: 0 0 20px;
    @media only screen and (min-width: $tablet-width) {
      margin: 0 30px 0 0;
      .DepositCrypto_verification {
        margin: 0;
      }
    }
  }

  .DepositCrypto_faq {
    @media only screen and (min-width: $tablet-width) {
      flex-basis: 390px;
    }
  }

  .DepositCrypto_input {
    margin: 0 0 20px 0;
    .v-menu__content {
      max-width: 100%;
      width: 100%;
    }
  }
}