@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';

































.CopyBtn {
  background: rgba(#2af3f3, 0.2);
  border-radius: 2px;
  cursor: pointer;
  width: 28px;
  height: 28px;
  position: relative;
  color: #00f3f4;
  svg {
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%);
  }
}
