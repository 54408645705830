@import '@/assets/scss/_variables.scss';

.DepositSepa_container {
  @media only screen and (min-width: $tablet-width) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.DepositSepa_form {
  flex-grow: 1;
  margin: 0 0 20px;
  @media only screen and (min-width: $tablet-width) {
    margin: 0 30px 0 0;
  }
}

.DepositSepa_row {
  display: grid;
  grid-template-columns: 30% 63% 28px;
  text-align: left;
  align-items: center;
  margin-bottom: 15px;
}

.DepositSepa_warning {
  background: rgba(#ff556d, 0.1);
  box-shadow: 0px 18px 34px rgba(34, 39, 47, 0.1);
  border-radius: 4px;
  color: #ff556d;
  font-size: 14px;
  max-width: 302px;
  padding: 14px;
  margin: 10px auto;
  text-align: left;
  line-height: 124%;
  border: 1px solid #FF556D;
  box-shadow: 0px 18px 34px rgba(34, 39, 47, 0.1);
  position: relative;
  display: flex;
  justify-content: center;
  &::before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6px 6px 6px;
    border-color: transparent transparent #FF556D transparent;
    position: absolute;
    top: -6px;
  }
  @media only screen and (min-width: $tablet-width) {
    margin: 0;
    margin-left: auto;
  }
}

.DepositSepa_label {
  font-size: 14px;
  line-height: 16px;
  color: #a6aebf;
}

.DepositSepa_field {
  font-size: 14px;
  line-height: 16px;
  color: #fff;
}

.DepositSepa_faq {
  @media only screen and (min-width: $tablet-width) {
    flex-basis: 390px;
  }
}

.DepositSepa_input {
  margin-bottom: 14px;
}

.DepositSepa_submit {
  margin-top: 15px;
}
