@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_variables.scss';

.Deposit_root {
  background: $primaryAppSectionBg;
  min-height: $page-container-height;
}

.Deposit_wrapper {
  padding: 10px;
  @media only screen and (min-width: $tablet-width) {
    padding: 20px 30px;
  }
}

.Deposit_backBtn {
  display: none;
  text-align: left;
  margin-bottom: 15px;
  @media only screen and (min-width: $tablet-width) {
    display: flex;
    margin-bottom: 26px;
  }
}

.Deposit_heading {
  font-size: 21px;
  line-height: 25px;
  color: #ffffff;
  text-align: left;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(#2af3f3, 0.2);
  span {
    color: #9395ab;
  }
  .Deposit_icon {
    vertical-align: bottom;
  }
}

.Deposit_methods {
  margin: 24px auto;
  max-width: 100%;
//   width: 520px;
  width: 115px;
  @media only screen and (min-width: $mobile-width) {
    width: 385px;
  }
  @media only screen and (min-width: $tablet-width) {
    width: 700px;
  }
}
