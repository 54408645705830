@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';









































































































































































































.custom-width-confirm-deposit-block {
  @media screen and (min-width: 992px) {
    width: 100%;
  }

  @media screen and (min-width: 1600px) {
    width: 45%;
  }
}
